<template>
<div v-if="journeyEndLoader" class="journey-end-loader">
  <div style="text-align: center;">
    <h1> Please stay on this page, your video KYC data is getting processed.</h1>
    <h3 class="mt-3 mb-2"> This might take a few moments. Please do not refresh or navigate away.</h3>
    <v-progress-circular
        :size="60"
        color="primary"
        indeterminate
    />
    <h4 class="mt-2"> Thank you for your patience.</h4>
  </div>
</div>
<div v-else>
  <div v-if="uploadProgress" class="upload-progress text-center">
    <v-progress-circular
      :size="60"
      color="primary"
      indeterminate
    >
    </v-progress-circular>
    <p class="mt-1">Uploading screenshot...</p>
  </div>
  <div class="text-center">
    <p class="salutation">{{ name }} You are now connected</p>
    <p class="mt-1">
      Please do not close your browser window or end the call before the
      verification is completed
    </p>
    <!-- <v-row justify="center">
      <v-col sm="12" cols="12" md="12" xl="12" lg="12"> -->
        <div class="main_body">
          <div class="mainVideo">
            <div
              class="remoteUser"
              :style="
                removeVideoMuted
                  ? { 'background-color': '#1E2432' }
                  : { 'background-color': 'transparent' }
              "
            >
              <div
                v-if="removeVideoMuted"
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                "
              >
                <img
                  src="@/assets/agent.svg"
                  @error="fallbackAgent"
                  class="mb-1"
                  alt="agent"
                  style="width: 40px"
                />
                <p style="color: white">Agent's camera is off.</p>
              </div>
              <video
                v-show="!removeVideoMuted"
                id="vid2"
                autoplay
                playsinline
              ></video>
              <img
                v-if="remoteAudioMuted"
                src="@/assets/mute.svg"
                @error="fallBackMuteImg"
                alt="mute"
                style="width: 25px; position: absolute; right: 5px; top: 5px"
              />
            </div>
            <div id="languageContainer" class="text-right">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn class="ma-2" text dark v-on="on">
                    <v-icon left>fas fa-globe-americas</v-icon>Language
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in languages"
                    :key="index"
                    @click="setLanguage(item)"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="feedback">
              <audio id="audioTracks" autoplay muted='false'></audio>
              <video v-if="enableChime" id="reflection" autoplay playsinline :style="{  paddingBottom: isVideoDisabled ? isMobile ? '130%' : '25%' : null  }">
              </video>
              <video v-else id="reflection" autoplay playsinline>
              </video>
              <div id="videoGradient" class="videoGrad">
                <div class="video-error-message" v-if="!adminLeft && !showOfflineMessage && (streamNotVisible || heartbeatFailed || speedThresholdFailed || noResponseFromRe)">
                  <div style="display: flex; align-items: center; flex-direction: column">
                    <div class="loader">Loading...</div>
                    <p
                    style="color: #fff; font-size: 18px; margin-top: 25px; background-color: rgba(30, 36, 50, 0.5); border-radius: 5px"
                    > {{videoErrorMessage}} </p>
                  </div>
                  <p v-if="showDropPromptHeartbeat" style="color: #fff; text-align: center">
                    Having trouble?
                      <strong
                        @click="finish(true)"
                        style="cursor: pointer"
                      > Click here </strong>
                    to end the call.
                  </p>
                </div>
              </div>
              <v-overlay
                v-if="showOfflineMessage && !onLine"
                :absolute="true"
                opacity="0"
              >
              <v-col
                  style="width: 20px; position: absolute; right: 15px; top: 0px;"
                >
                  <button
                    @click="forceCloseNoInternetPopUp()"
                  >
                   <img
                      class="pb-3"
                      src="@/assets/cancel_button.svg"
                      style="width: 15px"
                      @error ="fallBackCloseImg"
                    />
                  </button>
                </v-col>
                <v-row
                  class="ma-0 pa-3"
                  style="background-color: #fcfcfc; border-radius: 8px"
                  justify="center"
                  align="center"
                >
                <v-col align="center" style="width: 100%">
                    <img
                      class="pb-3"
                      src="@/assets/noInternet.svg"
                      @error="fallBackNoInternetImg"
                      style="width: 75px"
                    />
                    <p
                      class="mb-4"
                      style="
                        color: #ff0000;
                        letter-spacing: 0.64px;
                        font-size: 24px;
                        font-weight: 400;
                      "
                    >
                      No Internet
                    </p>
                    <p
                      style="
                        color: #0f0f0f;
                        font-size: 18px;
                        letter-spacing: 0px;
                        font-weight: 500;
                      "
                    >
                      Please wait while we try to reconnect you to the call.
                    </p>
                    <p style="color: #0f0f0f; font-size: 14px; letter-spacing: 0px">
                      If we are unable to reconnect in
                      <span id="timer-cont"> 10 </span> seconds, call will get
                      disconnected.
                    </p>
                  </v-col>
                </v-row>
              </v-overlay>
              <div id="idCardGradient" class="idCardGrad">
              </div>

            <div id="permissionBox" class="action-bar">
                <div class="questionary">
                <p style="color: white;">
                  {{
                    instructions && instructions[step]
                      ? instructions[step].text
                      : ""
                  }}
                </p>
              </div>

              <div class="action-btns"> 
                <div class="btns"> 
                  <!-- 
                    //Commenting because now this is not in use

                    <v-btn class="ma-2" text dark>
                        <v-icon left v-if="internetSpeed && internetSpeed.Download">fas fa-signal</v-icon>
                        <p class="ma-0 pa-0" style="text-transform: none">{{ internetSpeed && internetSpeed.Download ? internetSpeed.Download + ' Mbps' : "" }}</p>
                        <span v-if="internetSpeed && internetSpeed.Download ? parseFloat(internetSpeed.speedKbps) < parseFloat(speedThreshold) : false">
                          <img
                            class="pa-0 ml-2"
                            src="@/assets/error.svg"
                            @error="fallBackErrorImg"
                            style="width: 25px"
                          />
                        </span>
                      </v-btn> -->
                    
                      <v-btn
                        v-if="!isMute"
                        @click="muteAudioView()"
                        :disabled="disableMuteBtn"
                        class="mx-2"
                        fab
                        dark
                        small
                        outlined
                        color="white"
                      >
                        <v-icon dark small>fas fa-microphone</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="isMute"
                        @click="muteAudioView()"
                        class="mx-2"
                        fab
                        dark
                        small
                        outlined
                        color="white"
                      >
                        <v-icon dark small>fas fa-microphone-slash</v-icon>
                      </v-btn>
                    
                      <v-btn
                        v-if="!isVideoDisabled && allowCameraToggle"
                        @click="muteVideoView()"
                        class="mx-2"
                        fab
                        dark
                        small
                        outlined
                        color="white"
                      >
                        <v-icon dark small>fas fa-video</v-icon>
                      </v-btn>

                      <v-btn
                        v-if="isVideoDisabled && allowCameraToggle"
                        @click="muteVideoView()"
                        class="mx-2"
                        fab
                        dark
                        small
                        outlined
                        color="white"
                      >
                        <v-icon dark small>fas fa-video-slash</v-icon>
                      </v-btn>

                      <v-btn
                        v-if="isMobile && toogleCamera"
                        @click="changeCamera(true)"
                        :disabled="disableToggle"
                        class="mx-2"
                        fab
                        dark
                        small
                        outlined
                        color="white"
                      >
                        <v-icon dark small>fas fa-sync</v-icon>
                      </v-btn>

                      <v-btn
                        v-if="isMobile && enableChat"
                        color="transparent"
                        @click="showChatBox = !showChatBox"
                        
                      >
                        <div>
                          <img
                            src="@/assets/messageIcon.svg"
                            @error="fallbackMessageIcon"
                            style="width: 35px"
                          />
                          <v-icon v-if="showNotificationIcon" color="red" style="position: absolute;right: -10px; top: -10px; font-size: 20px;"> fas fa-circle</v-icon>
                        </div>
                      </v-btn>
                  
                      <v-btn
                      v-if="!isMobile && enableChat"
                        color="transparent"
                        @click="showChatBox = !showChatBox"
                        class="chatBtn"
                      >
                        <div class="chatImg">
                          <img
                            src="@/assets/messageIcon.svg"
                            @error="fallbackMessageIcon"
                            style="width: 30px;"
                          />
                          <v-icon v-if="showNotificationIcon" color="red" style="position: absolute;right: -10px; top: -10px; font-size: 20px;"> fas fa-circle</v-icon>
                        </div>
                      </v-btn>

                      <v-btn v-if="showClock" class="ma-2 timerBtn" text dark style="padding: 0px !important">
                        <v-icon left>fas fa-clock</v-icon>
                        {{ timeInCall }}
                      </v-btn>
                  
                  </div> 
              </div>
            </div>

          </div>
          </div>
          <v-dialog v-model="vpnCheckFailed" persistent max-width="550">
              <v-card>
                <v-card-text class="text-center pa-5">
                  <img src="@/assets/vpnCheckDialog.svg" />
                  <p class="title mt-3">
                    Looks like you’re using a VPN / Proxy Server.
                  </p>
                  <p class="body-2">
                    We understand your concerns for privacy and security. But the use of
                    a VPN / Proxy server is not compliant with the Video KYC guidelines.
                  </p>
                  <p class="subtitle-2">
                    Please turn off your VPN / Proxy server to participate In the Video
                    KYC process.
                  </p>
                </v-card-text>
                <v-card-actions class="pt-0 mr-2 pb-3">
                  <v-spacer></v-spacer>
                  <v-btn small dark color="signzy_blue" @click="startAgainButtonHandler()"
                    >Try again!</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          <div :class="showChatBox ? 'chatBox_show' : 'chatBox_hide'" v-if="showChatBox" @click="showNotificationIcon = false;">
            <Chat
              :participants="participants"
              :myself="myself"
              :messages="chatMessages"
              :chat-title="chatTitle"
              :placeholder="placeholder"
              :colors="colors"
              :hide-close-button="false"
              :displayHeader="true"
              :send-images="false"
              :profile-picture-config="profilePictureConfig"
              :scroll-bottom="scrollBottom"
              :asyncMode="asyncMode"
              @onMessageSubmit="onMessageSubmit"
              @onClose="onClose"
            />
          </div>
        </div>
        <!--microphone speaker dropdowns-->
        <div v-if="enableChime" style="text-align: left;padding-left: 10px;font-size: 16px;font-weight: bold;margin-top: 6px;">
          Select Devices
        </div>
        <div v-if="enableChime" class="deviceDropdownContainer" style="margin-top:15px;">
          <v-select :items="microphones" label="Microphone" item-text="label" class="deviceDropdown"
            item-value="deviceId" v-model="selectedMicrophone" @change="changeMicrophone($event)"
            dense outlined style="padding-left:10px;padding-right:10px;">
          </v-select>
          <v-select :items="videoDevices" label="Camera" item-text="label" item-value="deviceId" v-model="selectedVideoDevice"
            @change="changeVideoDevice($event)" class="deviceDropdown" style="padding-left:10px;padding-right:10px;" dense outlined>
          </v-select>
          <v-select :items="speakers" label="Speaker" item-text="label" item-value="deviceId" v-model="selectedSpeaker"
            @change="changeSpeaker($event)" class="deviceDropdown" style="padding-left:10px;padding-right:10px;" dense outlined>
          </v-select>
        </div>
    <!--microphone speaker dropdowns-->
      <!-- </v-col>
    </v-row> -->

    <v-dialog v-model="adminLeft" persistent max-width="550">
      <v-card>
        <v-card-text class="text-center">
          <p class="title pt-2">Your {{displayName}} representative has left the call!</p>
          <v-spacer></v-spacer>
          <v-btn
            dark
            @click="startAgainButtonHandler()"
            :style="{
              backgroundColor: `${$store.getters.callConfig.buttonOutlineColor || '#1365c0'}`,
              color: `${$store.getters.callConfig.buttonTextColor || null}`
            }"
            >Start Again!</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div>
      <v-tour name="reJoinTour" :steps="steps"></v-tour>
    </div>
  </div>


  </div>

</template>

<script>
import { calculateSpeed } from "@/Plugins/internetSpeed.js";
import { uploadFile } from "@/flux/persist.flux"
import {
  end_points,
  base_url,
  vcip_end_points,
  constants,
  host,
  chime_end_points
} from "@/config";
import axios from "axios";
import retry from "retry";
import { getBrowserData, getSession } from "@/Plugins/videoProcess";
import { SignzyVideoService } from "@/Plugins/SignzyVideoService";
import SignzyChimeVideoService  from "../../Plugins/SignzyChimeVideoService";
import { SignzyMessagingService } from "@/Plugins/SignzyMessagingService";
import { convertToRinger } from "@/Plugins/utils.js";
import { executeRedirection } from "@/flux/redirection.flux.js";
import { getTranslatedInstruction } from "@/flux/translataion.flux";
import { getPosition } from "@/Plugins/utils";
import noInternetImg from "../../assets/noInternet.png"
import closeImg from "../../assets/closeImg.png"
import errorImg  from "../../assets/error.png"
import muteImg from "../../assets/mute.png"
import agentImg from "../../assets/agent.png"
import {logReportData}  from  "@/Plugins/reportLogger.js";
import { Chat } from 'vue-quick-chat';
import '../../assets/css/vue-quick-chat.css'
import dummyImage from '../../assets/dummy-profile.png'
import messageIcon from '../../assets/message.png'
import {encrypt,decrypt} from '../../flux/encdec.flux'
import { mapActions } from "vuex";
import { endCustomer } from "@/assets/subStatus.json";
import CallState from "../../enums/CallStates"
import axiosLib from "axios";
import { userDataStore as store } from "@/Plugins/UserData.Store"
import { MISMiddleware } from "../../MISMiddleware"

let signzyStreamService;
let sms;
let offset = (new Date().getTimezoneOffset() / 60) * -1;
export default {
  components : {
    Chat
  },
  data: () => ({
    sessionId: "",
    name: "",
    videoErrorMessage: "",
    instructionDialog: false,
    multiSessionDialog: false,
    adminLeft: false,
    imageLogo: undefined,
    timeInCall: "00:00",
    timeElapsed: 0,
    isMobile: /Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
    instructionsText: "Please follow the instructions on the screen!",
    email: "",
    adminAlive: false,
    isMute: false,
    isScreenshare: false,
    isRecording: false,
    isVideoDisabled: false,
    step: -1,
    ipData: {
      city: "Location",
    },
    youtubeEmbed: "",
    streamNotVisible: false,
    disableMuteBtn:true,
    isBoxRequired: false,
    userOverlayRequired: false,
    callCompleted: false,
    forcedHangup: false,
    toogleCamera: false,
    tempToken: undefined,
    noResponseFromRe: false,
    notifcationSent: false,
    internetSpeed: {
      speedMbps: "0",
    },
    instructions: [
      {
        text: "",
      },
    ],
    virtualHost: undefined,
    isScreenshareActive: false,
    screenShareText: "Screen Share",
    isThankYouRequired: false,
    selfInstructions: [],
    language: "English",
    languages: ["English", "Hindi"],
    allowCameraToggle: false,
    width: "",
    height: "",
    removeVideoMuted: false,
    callStarted: false,
    host: host,
    useStunTurn: false,
    onLine: true,
    connectTimer: null,
    showOfflineMessage: false,
    internetSpeedChecker: null,
    remoteAudioMuted: false,
    internetSpeedInterval: 60,
    speedThreshold: 1500,
    speedThresholdFailed: false,
    showClock: false,
    isVideoConference: false,
    minimiseTimer: null,
    documentHidden: false,
    heartBeatInitiated: false,
    endUserToREHeartBeatInstance: null,
    endUserPingSent: false,
    endUserPingResponse: true,
    heartbeatFailed: false,
    endUserPingResponseTimer: null,
    showDropPromptHeartbeat: false,
    disableToggle: false,
    locationPermission: true,
    ringerInterval: null,
    isFrontendDebugLogs: false, // need ot be grant based in future
    FrontDebugLabel:"startvideoPage",
    displayName: "",
    isBlocked : false,
    rejoin: false,
    disableJitsiLogs : false,
    steps: [{
            target: '.rejoin',
            content: 'Facing Issues in Connectivity? Click this button to rejoin the same call!'
    }],
    participants:[{
      // name : "Banker", // removed as not present in the design
      id : 1,
      profilePicture: dummyImage
    }],
    myself: {
      // name : "Enduser",
      id : 2,
      profilePicture: dummyImage
    },
    placeholder: 'Type a message',
    chatTitle: "RE Agent",
    colors: {
        header: {
            bg: '#000000',
            text: '#fff'
        },
        message: {
            myself: {
                bg: '#fb4141',
                text: '#fff'
            },
            others: {
                bg: '#fb4141',
                text: '#fff'
            },
            messagesDisplay: {
                bg: '#f7f3f3'
            }
        },
        submitIcon: '#000000',
        submitImageIcon: '#000000',
    },
    chatMessages : [],
    profilePictureConfig: {
        others: true,
        myself: true,
        styles: {
            width: '30px',
            height: '30px',
            borderRadius: '50%'
        }
    },
    scrollBottom: {
        messageSent: true,
        messageReceived: true
    },
    notViewedMsgs: [],
    asyncMode: true,
    showChatBox : false,
    showNotificationIcon: false,
    enableChat: false,
    videoDevices: [],
    speakers: [],
    microphones: [],
    selectedVideoDevice: undefined,
    selectedMicrophone: undefined,
    selectedSpeaker: undefined,
    enableChime: false,
    frontCamera:true,
    globalConfigurations : ["enableChime","virtualHost"],
    allowedJitsiCodecs: ["VP8", "VP9"],
    jitsiCodec: "VP8",
    agentFeedbackData: null,
    locationInterval: null,
    forensicsInterval: null,
    allowForensicsEmit: true,
    logObject: endCustomer.startVideoPage,
    uploadProgress: false,
    showCancelBtn: false,
    abortController: new AbortController(),
    vpnCheckFailed: false,
    fetchIpIntervalId: null,
    previousIpAddress: null,
    journeyEndLoader: false
  }),
  props: ["requestId", "callData", "socket", "worker"],
  beforeDestroy() {
    if(!sessionStorage.getItem("popStateTriggered")){
      console.log("Destroying the component!!!!");
      if (this.internetSpeedChecker) {
        clearInterval(this.internetSpeedChecker);
      }
      if (!this.callCompleted && !this.forcedHangup) {
        if (this.requestId) {
          axiosInstance.post(end_points.update_call_timestamp(this.requestId), {
            abruptDrop: Date.now() + offset * 3600000,
          });
          //Commenting this since MIS logger since it's causing conflict with existing status
          // try{
          //   let initiationId = sessionStorage.getItem('initiationId');
          //   let data = {};
          //   data.status = "Unsuccessful";
          //   data.subStatus = "End user disconnected";
          //   data.callDuration = {};
          //   data.callDuration.endTime = Date.now();
          //   data.currentProcess = "end user disconnected mannually from start video";
          //   data.callId = this.requestId ? this.requestId : "";
          //   logReportData(this.socket,initiationId,data);
          // }
          // catch(err){
          //   console.log(err);
          // }
          // this.socket.emit("manualDisconnection", {cancelCleanUp: true});
        }
        window.parent.postMessage(
          { message: "eventOnCallDropAfterConnect" },
          "*"
        );

        //Commenting this part of the code since it's breaking the flow with undefined error on console
        // if (!this.adminLeft) {
        //   sms.emit("presenterLeft", {});
        //   sms.end();
        //   return null;
        // }
        // sms.end();
        // this.cleanUpListeners()
        // if(this.enableChime) {
        //   signzyStreamService.leaveRoom();
        // } else {
        //   signzyStreamService.leaveRoom();
        // }
      }
    }
  },
  methods: {
    ...mapActions(["pageEntry"]),
    async updateDroppedCall() {
      return new Promise(async (resolve, reject) => {
        try {
          await axiosInstance.post(end_points.dropped(sessionStorage.getItem("requestId")), {});
          resolve(true)
        } catch(err) {
          console.log("Failed to updated dropped priority : ", err);
          reject(err);
        }
      })
     
    },
    async startAgainButtonHandler(){
      await this.updateDroppedCall();
      if(sessionStorage.getItem("disableInstructionsPage")){
        this.$router.replace({ name: "prerequisite" });
      } else {
        this.$router.replace({ name: "instructions" });
      }
    },
    async fetchIpAddress() {
      let ipData = await axiosLib.get(store.getters.envValues?.ipFinder || vcip_end_points.ipFinder);

      if(this.previousIpAddress && ipData?.data?.ip_addr !== this.previousIpAddress) {
        this.checkForVPN();
        if(!this.vpnCheckFailed) this.previousIpAddress = ipData?.data?.ip_addr;
      }
      else this.previousIpAddress = ipData?.data?.ip_addr;
    },
    cleanUpListeners () {
      // if(navigator?.connection)
      //   navigator.connection.removeEventListener('change', this.checkForVPN);
      // else if(this.fetchIpIntervalId) {
      //   clearInterval(this.fetchIpIntervalId);
      // }
      window.removeEventListener("online", this.makeOnline);
      window.removeEventListener("offline", this.makeOffline);
      window.removeEventListener("visibilitychange", this.visibilityState);
    },
    forceCloseNoInternetPopUp() {
      this.showOfflineMessage = false;
    },
    makeOnline(e) {
      console.log("I am online");
      this.showOfflineMessage = false;
      this.onLine = true;
      // this.checkForVPN();
      if (this.connectTimer) {
        clearInterval(this.connectTimer);

      }
    },
    fallBackNoInternetImg(e) {
      e.target.src = noInternetImg
    },
    fallBackCloseImg(e) {
      e.target.src = closeImg
    },
    fallBackErrorImg(e) {
      e.target.src = errorImg
    },
    fallBackMuteImg(e) {
      e.target.src = muteImg
    },
    fallbackAgent(e) {
      e.target.src = agentImg
    },
    fallbackMessageIcon(e) {
      e.target.src = messageIcon
    },
    sendHeartBeat(){
      this.heartBeatInitiated = true;
      this.endUserToREHeartBeatInstance = setInterval(() => {
        if (this.onLine) {
          if (!this.endUserPingSent) {
              sms.emit('endUserPing', {timeStamp: Date.now()});
              this.endUserPingResponse = false;
              this.endUserPingSent = true;
              this.endUserPingResponseTimer = setTimeout(() => {
                if(!this.endUserPingResponse){
                  this.endUserPingSent = false;
                  this.heartBeatFailureHandler();
                }
              }, 3000 + 500)
          }
        }

      },1000);


    },
    heartBeatFailureHandler(){
      if (this.noResponseFromRe) {
        this.showDropPromptHeartbeat = true;
      } else {
        if (navigator.onLine) {
          this.videoErrorMessage = `Facing connectivity issues, Please wait`;
        } else {
          this.videoErrorMessage = "It looks like your are Offline, Please check your internet connection.";
        }
        this.noResponseFromRe = true;
      }
    },
    makeOffline(e) {
      console.log("I am offline");
      this.onLine = false;
      this.showOfflineMessage = true;
      let x = 10;
      this.connectTimer = setInterval(() => {
        if (x > 0) {
          x -= 1;
          document.getElementById("timer-cont").innerHTML = x;
        } else {
          clearInterval(this.connectTimer);
          // sessionStorage.setItem('isOffline', true);
          this.hangUp("offline");
        }
      }, 1000);
    },
    jitsiEvents(event, data) {
      switch(event) {
        case "rejoin" :
            this.$tours['reJoinTour'].start();
            this.rejoin = data;
            break;
        case "logs" :
            this.addLogToReport(data);
            break;
        case "message" :
            if(sms) {
                sms.emit(data.event, data);
            }
            break;
        default :
            console.warn("Wrong jitsi event", event);
      }
    },
    reJoinCall() {
      this.$tours['reJoinTour'].stop();
      this.rejoin = false;
      signzyStreamService.retryConnecting();
    },
    addLogToReport(data) {
      try {
        let initiationId = sessionStorage.getItem('initiationId');
        let formData = {};
        formData.currentProcess = `Video Conference Error - Enduser - ${data}`;
        formData.callId = this.requestId ? this.requestId : "";
        logReportData(this.socket,initiationId,formData);
      }
      catch(err) {
        console.error(err);
      }
    },
    async changeMicrophone(deviceId) {
      if(deviceId) {
        await signzyStreamService.setMicrophone(deviceId);
      }
    },
    async changeSpeaker(deviceId) {
      if(deviceId) {
        await signzyStreamService.setSpeaker(deviceId);
      }
    },
    async changeVideoDevice(deviceId) {
      if(deviceId) {
        await signzyStreamService.setVideoInputDevice(deviceId);
      }
    },
    onMessageSubmit: function(message) {
      try {
        message.index = this.chatMessages.length;
        this.chatMessages.push(message);
        message.myself = false;
        sms.emit("endUserMessage", message);
      } catch(err) {
        console.error("Some error sending message : ", err);
      }
    },
    messageSeenAck() {
      sms.emit("bankerMessageSeenAck");
    },
    receivedMessage(data) {
      try {
        let chatObj = {
          content : data.message,
          participantId : 1,
          type: "text",
          myself: false
        }
        this.chatMessages.push(chatObj);
      } catch(err) {
        console.error("Error showing received message:", err);
      }
    },
    onType: function(event) {
      // console.log("banker is typing....");
    },
    onClose: function() {
      this.showChatBox = false;
    },
    async finish() {
        try{
          let initiationId = sessionStorage.getItem('initiationId');
          let formData = { ...this.logObject.internetIssue };
          formData.callDuration = {};
          formData.callDuration.endTime = Date.now();
          formData.currentProcess = "RE didn't respond to the heartbeat";
          formData.callId = this.requestId ? this.requestId : "";
          logReportData(this.socket,initiationId,formData);
        }
        catch(err){
          console.log(err);
        }
      try {
        console.log("RE didn't respond to the heartbeat");
        if(this.enableChime) {
          signzyStreamService.leaveRoom();
        } else {
          await signzyStreamService.leaveRoom();
        }
      } catch (err) {
        console.log("Something went wrong in leaving room", err);
      }
      if (this.endUserToREHeartBeatInstance) {
        clearInterval(this.endUserToREHeartBeatInstance);
      }
      this.socket.emit('callLeft');
      sms.end();
      this.cleanUpListeners();
        try {
          let initiationId = sessionStorage.getItem('initiationId');
          let data = { ...this.logObject.internetIssue };
          data.callDuration = {};
          data.callDuration.endTime = Date.now();
          data.callId = this.requestId ? this.requestId : "";
          logReportData(this.socket,initiationId,data);
        } catch(err) {
            console.log(err);
        }
      this.socket.disconnect();
      this.$router.replace({
        path: "/unexpectedDrop/ReDrop",
      });
    },
    promptMessage(e) {
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      e.returnValue = "Do you really want to leave the application in middle?";
    },
    workOnLayouts() {
      setTimeout(() => {
        let constraints = {
          audio: false,
          video: {
            facingMode: "user",
          },
        };
        let video = document.getElementById("reflection");
        let permissionBox = document.getElementById("permissionBox");
        let videoGradient = document.getElementById("videoGradient");
        let languageContainer = document.getElementById("languageContainer");
        //Setting width
        this.width = `${
          this.$store.getters.endUserData["videoWidth"] || video.offsetWidth
        }px`;
        this.height = `${
          this.$store.getters.endUserData["videoHeight"] || video.offsetHeight
        }px`;
        languageContainer.style.width = this.width;
        permissionBox.style.width = this.width;
        permissionBox.style.position = "absolute";
        videoGradient.style.width = this.width;
        videoGradient.style.height = this.height;
        videoGradient.style.position = "absolute";
      }, 50);
    },
    async changeCamera(performToggle) {
      if(!this.isVideoDisabled){
        eventBus.$emit("vueLoaderTextChange", performToggle ? "Toggling camera, please wait..." : "Getting your camera feed, please wait.....");
        eventBus.$emit("vueLoader", true);
        sms.emit("cameraToggling", {check : true});
        this.disableToggle = true;
        try {
          if(this.enableChime){
            //toggle the flag only if changeCamera is called for actual toggling
            if(performToggle) {
              this.frontCamera = !this.frontCamera;
            }
            signzyStreamService.switchCamera(!this.frontCamera).then((response) => {
              //check for mute while switching and maintain state
              this.isMute
              ? signzyStreamService.muteTrack("audio")
              : signzyStreamService.unMuteTrack("audio");

              sms.emit("cameraToggling", {check: false});
              eventBus.$emit("vueLoader", false);
              setTimeout(() => {  this.disableToggle = false; console.log("toogle disabled!"); }, 4000);
            }).catch((err) => {
              console.log("Error in toggle / recapture", err);
              eventBus.$emit("vueSnack", performToggle ? "Something went wrong during toggle!" : "Something went wrong in recapturing your camera feed!");
              sms.emit("cameraToggling", {check: false});
              eventBus.$emit("vueLoader", false);
              setTimeout(() => {  this.disableToggle = false; console.log("toogle disabled!"); }, 4000);
            })
          }else{
            signzyStreamService.switchCamera().then((response) => {
              //This is not required anymore since we are already preserving the state in the class
              //check for mute while switching and maintain state
              // this.isMute
              // ? signzyStreamService.muteTrack("audio")
              // : signzyStreamService.unMuteTrack("audio");

              sms.emit("cameraToggling", {check: false});
              eventBus.$emit("vueLoader", false);
              setTimeout(() => {  this.disableToggle = false; console.log("toogle disabled!"); }, 3000);
            }).catch((err) => {
              console.log("Error in toggle / recapture", err);
              eventBus.$emit("vueSnack", performToggle ? "Something went wrong during toggle!" : "Something went wrong in recapturing your camera feed!");
              sms.emit("cameraToggling", {check: false});
              eventBus.$emit("vueLoader", false);
              setTimeout(() => {  this.disableToggle = false; console.log("toogle disabled!"); }, 3000);
            })
          }
        } catch (err) {
          console.log("Something went wrong!!!!", err);
          eventBus.$emit("vueSnack", performToggle ? "Something went wrong during toggle!" : "Something went wrong in recapturing your camera feed!");
          setTimeout(() => { this.disableToggle = false; console.log("toogle disabled!"); }, 3000);
          sms.emit("cameraToggling", {check: false});
          eventBus.$emit("vueLoader", false);
        }
    }},
    showIDOverlay() {
      let idCardGradient = document.getElementById("idCardGradient");
      idCardGradient.style.width = this.width;
      idCardGradient.style.height = this.height;
      idCardGradient.style.position = "absolute";
      idCardGradient.style.display = "flex";
    },
    hideIDOverlay() {
      let idCardGradient = document.getElementById("idCardGradient");
      idCardGradient.style.display = "none";
    },
    async setLanguage(language) {
      //pull transaltions
      if (!this.selfInstructions[0].translations[language]) {
        this.selfInstructions = await getTranslatedInstruction(
          this.selfInstructions,
          language
        );
      }
      for (var i = 0; i < this.selfInstructions.length; i++) {
        this.instructions[i].text = this.selfInstructions[i].translations[
          language
        ];
      }

      sms.emit("language", {
        language,
      });
    },
    muteAudioView() {
      this.isMute = !this.isMute;
      this.isMute
        ? signzyStreamService.muteTrack("audio")
        : signzyStreamService.unMuteTrack("audio");
      sms.emit("customerAudioMuted", this.isMute); //emitting event when customer is muted or unmuted.
    },
    muteVideoView() {
      this.isVideoDisabled = !this.isVideoDisabled;
      let flag = this.isVideoDisabled;
      sms.emit("remoteCameraTweak", {
        flag
      })

      let reflectionContainer = document.getElementById("reflection");
      if(this.isVideoDisabled){
        signzyStreamService.muteTrack("video");
        reflectionContainer.style.height = `${this.$store.getters.endUserData["videoHeight"] || 350}px`;
      } else {
        reflectionContainer.style.height = "";
        signzyStreamService.unMuteTrack("video");
      }
    },
    muteRemoteVideo(muted) {
      this.removeVideoMuted = muted;
    },
    clearScreenshotUpload(reason = "unknown reason") {
      this.abortController.abort();
      this.abortController = new AbortController();
      let initiationId = sessionStorage.getItem('initiationId');
      let data = {
        currentProcess: reason,
        // subStatus: "Capturing Screenshot"
      }
      logReportData(this.socket,initiationId,data);
    },
    async uploadFile(options) {
      try {
        return await uploadFile(options);
      } catch (error) {
        throw(error);
      } finally {
        this.uploadProgress = false;
      }
    },
    async updloadB64(dataURL) {
      try {
        let persist = this.$store.getters.envValues?.persistBase64;

        if (this.callData.configuration.persistExternalHost) {
          const persistHost = new URL(this.callData.configuration.persistExternalHost);
          persist = new URL(this.$store.getters.envValues?.persistBase64);
          persist.hostname = persistHost.hostname;
          persist = persist.href;
        }

        let imageUrl;
        if (this.$store.getters.envValues?.encPersist === "true") {
          imageUrl = await this.uploadFile({
            url: base_url + end_points.upload_files,
            data: {
              base64string: encrypt(dataURL.split("base64,")[1], this.$store.getters.envValues.otpEncKey),
              ttl: encrypt("7 days", this.$store.getters.envValues.otpEncKey),
              mimetype: encrypt("image/jpeg", this.$store.getters.envValues.otpEncKey),
            },
            token: this.tempToken,
            requestId: this.requestId,
            abortSignal: this.abortController.signal,
            onUploadProgress: (progressEvent) => {
              sms.emit("screenshotUploadProgress", {
                loaded: progressEvent.loaded,
                total: progressEvent.total
              });
            },
          });
        } else {
          let formData = new FormData();
          formData.append("base64string",  encrypt(dataURL, this.$store.getters.envValues.otpEncKey));
          formData.append("mimetype", encrypt("image/jpeg", this.$store.getters.envValues.otpEncKey));
          formData.append("ttl", encrypt("7 days", this.$store.getters.envValues.otpEncKey));

          imageUrl = await this.uploadFile({
            url: base_url + end_points.upload_files,
            data: formData,
            token: this.tempToken,
            requestId: this.requestId,
            abortSignal: this.abortController.signal,
            onUploadProgress: (progressEvent) => {
              sms.emit("screenshotUploadProgress", {
                loaded: progressEvent.loaded,
                total: progressEvent.total
              });            
            },
          });
        }

        return imageUrl;
      } catch (error) {
        throw(error);
      }
    },
    async captureScreenshot() {
      try {
        // Disabling toggle for 2 seconds when RE is capturing the screenshot
        this.disableToggle = true;
        setTimeout(() => {
          this.disableToggle = false;
        }, 2000);

        let videoToCapture = document.getElementById("reflection");

        if (videoToCapture) {
          setTimeout(async () => {
            try {
              let canvasAR = videoToCapture.videoWidth / videoToCapture.videoHeight;
              let canvas = document.getElementById("screenCapture");
              canvas.width = 900;
              canvas.height = 900 / canvasAR;
              let ctx = canvas.getContext("2d");
              ctx.drawImage(videoToCapture, 0, 0, canvas.width, canvas.height);
              let dataURL = canvas.toDataURL("image/jpeg");
              this.uploadProgress = true;

              try {
                let imageUrl = await this.updloadB64(dataURL);

                if(!imageUrl) {
                  this.clearScreenshotUpload("screenshot captured failed");
                  sms.emit("capturingScreenshotFailed");
                } else {
                  this.clearScreenshotUpload("screenshot captured successfully");
                  sms.emit("capturedScreenshot", imageUrl);
                }
              } catch (error) {
                throw(error);
              }
            } catch(error) {
              console.error("Error in captureScreenshot:", error.message);
              sms.emit("capturingScreenshotFailed");
              this.clearScreenshotUpload(error.message);
            }
          }, 100);
        }
      } catch (error) {
        console.error("Error in captureScreenshot:", error);
        this.clearScreenshotUpload(error.message);
      }
    },
    isLoadedInIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    async getLocationData(data, ipInfo) {
      try{
        let resp = await axiosInstance.post(vcip_end_points.location,data)
            this.ipData = resp;
            this.ipData.data.regionName = this.ipData.data.state;
            this.ipData.data.ipAddress = ipInfo && ipInfo.data && ipInfo.data.ip_addr ? ipInfo.data.ip_addr : "";
            this.ipData.data.latitude = localStorage.getItem('location_lat');
            this.ipData.data.longitude = localStorage.getItem('location_lon');
            sms.emit("ipData", this.ipData.data);
            try{
              let initiationId = sessionStorage.getItem('initiationId');
              let data = {};
              data.locationData ={};
              data.locationData.city = this.ipData.data.city;
              data.locationData.complete = this.ipData.data.complete;
              data.locationData.state = this.ipData.data.state;
              data.locationData.pincode = this.ipData.data.zipcode;
              data.locationData.countryName = this.ipData.data.countryName;
              data.onPage = "startVideo";
              data.sessionId = this.sessionId;
              data.callId = this.requestId ? this.requestId : "";
              logReportData(this.socket,initiationId,data);
            }
            catch(err){
              console.log(err);
            }
          return true;
      }
      catch(err){
          this.ipData.data.regionName = "N/A";
          this.ipData.data.ipAddress = ipInfo && ipInfo.data && ipInfo.data.ip_addr ? ipInfo.data.ip_addr : ""; // empty string inside Ip means, Ip fetching failed
          this.ipData.data.latitude = localStorage.getItem('location_lat');
          this.ipData.data.longitude = localStorage.getItem('location_lon');
          console.log("Location data execution failed", err);
          if(this.isFrontendDebugLogs){
            sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId, error:  err, type: "locationFailure0"}});
          }
          return false;
      }
    },
    async setGlobalConfigurations(globalConfigs) {
      try {
        if(globalConfigs) {
          for(let i = 0 ; i < this.globalConfigurations.length ; i++) {
            let configName = this.globalConfigurations[i];
            this[configName] = globalConfigs[configName] ? globalConfigs[configName] : this[configName];
          }
          if(globalConfigs?.recordingCodec) {
            const fetchedCodec = globalConfigs.recordingCodec?.split?.("=")?.pop?.()?.toUpperCase?.() ?? "VP8";
            this.jitsiCodec = this.allowedJitsiCodecs.includes(fetchedCodec) ? fetchedCodec : "VP8";
          }
          console.log("this is the configuration that is set",this);
        }
      } catch(err) {
        console.error("Error setting global configurations", err);
      }
    },
    async forensicsLocationHandler() {
      let ipInfo
      //added delay with retry beacuse of scheduling BE docker was restarting resulting in 502

      try {
        const operation = retry.operation({
          retries: 10,
          factor: 3,
          minTimeout: 1 * 1000,
          maxTimeout: 60 * 1000,
        });
        
        let options = {
          timeout: 1000 * 15
        }

        ipInfo = await axios.get(this.$store.getters.envValues?.ipFinder || vcip_end_points.ipFinder, options);
        // console.log("ipInfo",ipInfo)

        operation.attempt(async (currentAttempt) => {

          console.log('sending forensics request: ', currentAttempt, ' attempt');

          try {
            let encryptedIPAddress = encrypt(ipInfo.data.ip_addr, this.$store.getters.envValues.otpEncKey)

            let encryForensicsData = (await axios.post(vcip_end_points.forensics, { ip: encryptedIPAddress }))
            let decryptedData = decrypt(encryForensicsData.data, this.$store.getters.envValues.otpEncKey)
            let forensicsData = JSON.parse(decryptedData);

            this.forensicsInterval = setInterval(() => {
              if (this.allowForensicsEmit) {
                sms.emit("forensicsData", forensicsData.data);
              }
            }, 2000);

            sms.on("forensicsDataAck", (ack) => {
              if (ack) {
                this.allowForensicsEmit = false;
                window.clearInterval(this.forensicsInterval);
              }
            });

          } catch (e) {
            console.log("forensic operations error", e)
            if (operation.retry(e)) { return; }
          }
        });

      } catch (error) {
        if (this.isFrontendDebugLogs) {
          sms.emit("Error Logger##", { VKYClogger: { scope: this.FrontDebugLabel, timeStamp: Date.now(), for: this.sessionId, error: error, type: "forensicsFailure" } })
        }
        console.log("forencicsData", error);

        sms.emit("forensicsData", {
          status: "failed",
        });

      }

      try {
        if (localStorage.getItem('location_lat') && localStorage.getItem('location_lon')) {
          let resp = await this.getLocationData({lat: localStorage.getItem('location_lat'), lon: localStorage.getItem('location_lon')}, ipInfo);
          if (!resp) {
            if(this.isFrontendDebugLogs){
              sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId, error:  'Issue in getting location, default values being sent', type: "locationFailure0"}});
            }
            console.log("Some issue encountered in getting location, default values are being sent");
          }
        } else {
          try{
            let data = await getPosition();

            localStorage.setItem('location_lat', data.coords.latitude);
            localStorage.setItem('location_lon', data.coords.longitude);
            let resp = await this.getLocationData({lat: data.coords.latitude, lon: data.coords.longitude}, ipInfo);
            if (!resp) {
              console.log("Some issue encountered in getting location, default values are being sent");
            }
        }
          catch(err){
          sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId, error:  err, type: "locationFailure0"}});
          console.log("GEO Location Error::::", err);
          }
        }
      } catch (error) {
        if (this.isFrontendDebugLogs) {
          sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId, error:  error, type: "locationFailure0"}});
          localStorage.getItem('location_lat') ? sms.emit("Error Logger##" , {VKYClogger: {timeStamp: Date.now(), for: this.sessionId, data: localStorage.getItem('location_lat')  , type: "lat"}}) : sms.emit("Error Logger##", {VKYClogger: {timeStamp: Date.now(), for: this.sessionId, data: "No lat"}});
          localStorage.getItem('location_lon') ? sms.emit("Error Logger##" , {VKYClogger: {timeStamp: Date.now(), for: this.sessionId, data: localStorage.getItem('location_lon')  , type: "lon"}}) : sms.emit("Error Logger##", {VKYClogger: {timeStamp: Date.now(), for: this.sessionId, data: "No lon"}});
        }
        console.log("Something went wrong here",  error)
        this.ipData = {
          data: {},
        };
        this.ipData.data.regionName = "N/A";
        this.ipData.data.ipAddress = ipInfo && ipInfo.data && ipInfo.data.ip_addr ? ipInfo.data.ip_addr : ""; // empty string inside Ip means, Ip fetching failed
        this.ipData.data.latitude = localStorage.getItem('location_lat');
        this.ipData.data.longitude = localStorage.getItem('location_lon');
        sms.emit("ipData", this.ipData.data);
      } finally {
        //Sending browserData
         let browserData = await getBrowserData();
         let allowLocationEmit= true;
        this.locationInterval = setInterval(() => {
          if (allowLocationEmit) {
            sms.emit("setLocation", {
              geo: this.ipData.data,
              browser: browserData,
            });
          }
        }, 2000);

        sms.on("locationDataAck", (ack) => {
          if (ack) {
            allowLocationEmit = false;
            window.clearInterval(this.locationInterval);
          }
        });
      }
    },
    async hangUp(event) {
      try {
        if(this.enableChime) {
          signzyStreamService.leaveRoom();
        } else {
          await signzyStreamService.leaveRoom();
        }

      } catch (error) {
        console.log(error);
      }


      this.socket.emit("manualDisconnection", {cancelCleanUp: true});
      window.clearInterval(this.ringerInterval);
      window.clearInterval(this.endUserToREHeartBeatInstance);
      this.videoErrorMessage = "";
      this.streamNotVisible = false;
      this.heartbeatFailed = false;
      this.speedThresholdFailed = false;
      this.noResponseFromRe = false;
      this.cleanUpListeners();

      // Posting success message to integrator Only when
      // hangUp is happening wihtout any error
      if (!event || event !== "offline") {
        window.parent.postMessage({ message: "videoUploadingDone", agentFeedbackData: this.agentFeedbackData }, "*");
        axiosInstance.post(end_points.trigger_call_back(this.requestId), {
          eventFor: "videoUploadingDone",
          agentFeedbackData: this.agentFeedbackData
        });
      }

      if (event === 'offline') {
        try {
          let initiationId = sessionStorage.getItem('initiationId');
          let data = {};
          data.status = "Unsuccessful (Technical failure)";
          data.subStatus = "Customer network is too slow";
          data.callId = this.requestId ? this.requestId : "";
          logReportData(this.socket,initiationId,data);
        } catch(err) {
            console.log(err);
        }
        this.$router.replace({
          path: "/unexpectedDrop/networkTooSlow",
        });
      } else if (this.callData.configuration.redirectOnSuccessful) {
        window.open(
          executeRedirection(
            this.callData.configuration.displayName,
            this.timeElapsed,
            this.callData
          ),
          "_self"
        );
      }else if (this.callData.configuration.isThankYouRequired){

        if(!this.$store.getters.endUserData["isSchedule"] ||
          (this.$store.getters.endUserData["isSchedule"] &&
           !this.callData.configuration.disableThankYouPageOnReschedule)
        ){
          this.$router.push({
            path: "/thankyou",
          });
        }
      } else {
        //window.parent.postMessage("videoUploadingDone", "*");
        window.parent.postMessage({ message: "videoUploadingDone", agentFeedbackData: this.agentFeedbackData }, "*");
        this.journeyEndLoader = true;
        axiosInstance.post(end_points.trigger_call_back(this.requestId), {
          eventFor: "videoUploadingDone",
          agentFeedbackData: this.agentFeedbackData
        });
      }
    },
    showLocalNotification (title, body, swRegistration) {
      const options = {
          body
          // here you can add more properties like icon, image, vibrate, etc.
      };
      if (localStorage.getItem("imageLogo")) {
        options["image"] = localStorage.getItem("imageLogo");
      }
      swRegistration.showNotification(title, options);
    },
    visibilityState(prop) {
      console.log("Visibilty", document.hidden);
      try {
          let inititalCheck = prop;
          // tell re user's visibility
          sms.emit("minimizedWindow", {minimized: document.hidden});
            if (document.hidden && Notification.permission == "granted" && !this.notifcationSent) {
              try {
                if(this.$store.getters.envValues?.client == 'citi' || this.$store.getters.envValues?.client == 'omega') {
                  this.showLocalNotification(`Go back to your browser to complete your Video KYC for Citi application`, ``, this.worker);
                } else {
                  this.showLocalNotification(`Click here to resume your on-going video call or your call will be disconnected`, ``, this.worker);
                }
                this.notifcationSent = true;
              } catch (err) {
                console.log("Error in sending notification", err);
                this.notficationSent = false;
              }
            } else {
                if (this.isMobile && inititalCheck) {
                  console.log("Captruing stream back");
                  // false flag will recapture the current facing mode stream
                  setTimeout(() => {
                    if(this.enableChime){
                      this.changeCamera(false);
                    }
                  }, 600)
                }
              this.notifcationSent = false;
            }
      } catch (err) {
        console.log("Couldn't capture visibility::::", err);
      }
    },
    async checkForVPN() {
      this.vpnCheckFailed = false;
      if (this.$store.getters.disableVPNCheck) {
        this.vpnCheckFailed = false;
      } else {
          try {
            if (navigator.onLine) {
              let forensicsData = await MISMiddleware.isVPNConnected(this.requestId);

              if (forensicsData?.data?.block == 0) {
                this.vpnCheckFailed = false;
              } else {
                this.vpnCheckFailed = true;
              }
            }
          } catch (err) {
              console.error("Could not check ipData", err);
              this.vpnCheckFailed = true;
              try {
                  let data = { ...this.logObject.vpnCheckFailed };
                  data.subStatus = "VPN Detection failed at Customer Side"
                  let initiationId = sessionStorage.getItem('initiationId');
                  data.currentProcess = "Vpn check failed";
                  data.callId = this.requestId ? this.requestId : "";
                  logReportData(this.socket, initiationId, data);
              } catch (err) {
                  console.error(err);
              }
          }
      }
      if( this.vpnCheckFailed ) {
        clearInterval(this.fetchIpIntervalId)
        sms.emit("endUserVpnDetected");
      }
    }
  },
  mounted() {
    window.addEventListener("online", this.makeOnline);
    window.addEventListener("offline", this.makeOffline);
    window.addEventListener("visibilitychange", this.visibilityState);
    // as this is a dom manupulation operation, should be done when mounted and not when created
    // calculateSpeed('EU', (res) => {
    //   try {
    //     if (res && res.status && res.status === "failed") {
    //       console.log("Internet speed failed");
    //       this.internetSpeed = res;
    //       sms.emit("internetSpeed", this.internetSpeed);
    //       try {
    //           let initiationId = sessionStorage.getItem('initiationId');
    //           let data = {};
    //           data.onPage = "startVideo"
    //           data.currentProcess = `Internet speed capture failed at start video page`;
    //           data.endUserInternetSpeed = 0;
    //           data.callId = this.requestId ? this.requestId : "";
    //           logReportData(this.socket,initiationId,data);
    //       } catch(err){
    //           console.log(err);
    //       }
    //     } else {
    //       this.internetSpeed = res;
    //       sms.emit("internetSpeed", this.internetSpeed);
    //       try {
    //           let initiationId = sessionStorage.getItem('initiationId');
    //           let data = {};
    //           data.onPage = "startVideo"
    //           data.currentProcess = `Internet speed captured from start video page`;
    //           data.endUserInternetSpeed = res.Download ? res.Download:res.speedKbps/1024;
    //           data.callId = this.requestId ? this.requestId : "";
    //           logReportData(this.socket,initiationId,data);
    //       } catch(err){
    //           console.log(err);
    //       }

    //     }
    //   } catch(err) {
    //       console.log("Something went wrong", err);
    //   }
    // });
    let audioTrack = document.getElementById('audioTracks');
    if(audioTrack) {
      audioTrack.muted = false;
    }
  },
  beforeRouteEnter (to, from, next) {
    if(sessionStorage.getItem("onPage") == "StartVideo"){
      sessionStorage.setItem("StartVideoRefreshed", true);
      sessionStorage.setItem("triggerCustomMIS", true);
      sessionStorage.setItem("fromPage", "StartVideo");
    }
    next();
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch("pageExitCleanUp", "StartVideo");
    next();
  },
  watch: {
    showChatBox(newVal, oldVal) {
     if(newVal){
      this.messageSeenAck();
      this.showNotificationIcon = false;
     }
    }
  },
  async created() {
    // this.checkForVPN();
    // if(navigator?.connection)
    //   navigator.connection.addEventListener('change', this.checkForVPN);
    // else {
    //   this.fetchIpIntervalId = setInterval(this.fetchIpAddress, 10000);
    // }
    this.pageEntry("StartVideo");
    document.documentElement.style.setProperty('--available-height', `${window.innerHeight}px`);
    try{
        navigator.permissions.query({name:'geolocation'})
        .then(function(permissionStatus) {
          permissionStatus.onchange = function() {
            if(this.state === "denied"){
              sms.emit("locationDenied", {check: true});
              this.locationPermission = false;
            }
            else{
              sms.emit("locationDenied", {check: false});
              this.locationPermission = true;
            }
          };
        });
    }
    catch{
      console.log("issue in fetching location permissions")
    }
    // the below line is here to prevent to call dequeue in case of back button or refresh (user has to come through pre-requisite page to dequeue)
    this.$store.commit("setBackButtonState",true)

    this.displayName = this.callData.configuration.displayName;
    if (this.callData.configuration.isFrontendDebugLogs) {
      this.isFrontendDebugLogs = this.callData.configuration.isFrontendDebugLogs;
    }
    if(this.callData && this.callData.configuration) {
      this.disableJitsiLogs = this.callData.configuration.disableJitsiLogs === true ? true : false;
    }
    if (!this.$store.getters.initiated) {
      this.cleanUpListeners();
      if (this.sessionId) {
        this.$router.replace({
          name: "initiateConf",
        });
      } else {
        if(sessionStorage.getItem("disableInstructionsPage")){
          this.$router.replace({ name: "prerequisite" });
        } else {
          this.$router.replace({ name: "instructions" });
        }
      }
    } else {
      if (
        this.callData.users &&
        this.callData.users[0] &&
        this.callData.users[0].name
      ) {
        this.name = `${this.callData.users[0].name}, `;
        if (this.name) {
          this.name = this.name[0].toUpperCase() + this.name.substr(1);
        }
      }

      const canvas = document.createElement("canvas");
      canvas.setAttribute("id", "screenCapture");
      canvas.style =
        "display:none;opacity:0;position:absolute;z-index:-1;top: -100000000;left:-1000000000; margin-top:-1000000000;margin-left:-1000000000;";
      (document.body || document.documentElement).appendChild(canvas);

      window.addEventListener('popstate' , async (e) => {
        sessionStorage.setItem("popStateTriggered", true);
        if (this.internetSpeedChecker) {
          clearInterval(this.internetSpeedChecker);
        }
        if (!this.callCompleted && !this.forcedHangup) {
          if (this.requestId) {
            axiosInstance.post(end_points.update_call_timestamp(this.requestId), {
              abruptDrop: Date.now() + offset * 3600000,
            });
            try {
              let initiationId = sessionStorage.getItem('initiationId');
              let data = { ...this.logObject.abruptDrop };
              data.callDuration = {};
              data.callDuration.endTime = Date.now();
              data.currentProcess = "manual disconnection in start Video"
              data.callId = this.requestId ? this.requestId : "";
              logReportData(this.socket,initiationId,data);
            } catch(err){
              console.log(err);
            }
            this.socket.emit("manualDisconnection", {cancelCleanUp: true});
          }
          window.parent.postMessage(
            { message: "eventOnCallDropAfterConnect" },
            "*"
          );
          // Added try-catch block 
          // signzyStreamService.leaveRoom() throws an error if the user has 
          // already left the room
          try {
            if(this.enableChime) {
              signzyStreamService.leaveRoom();
            } else {
              await signzyStreamService.leaveRoom();
            }
          } catch(err) {
            console.error("User has already left the room")
            console.error({err})
          }
          this.cleanUpListeners();

          sessionStorage.setItem("backButtonPressed", true);
          sessionStorage.setItem("triggerCustomMIS", true);
          sessionStorage.setItem("fromPage", "StartVideo");
          if(sessionStorage.getItem("disableInstructionsPage")){
            this.$router.replace({ name: "prerequisite" });
          } else {
            this.$router.replace({ name: "instructions" });
          }

          if (!this.adminLeft) {
            sms.emit("presenterLeft", {});
            return null;
          }
        }
      }, false)

      window.onbeforeunload = () => {
        if (!this.callCompleted && !this.forcedHangup) {
          // set the following flag to check if the user has closed the page or if they have refreshed the page
          if(!this.adminLeft){
            sessionStorage.setItem('refreshedStartVideoPage', true)
            try {
              let initiationId = sessionStorage.getItem('initiationId');
              let data = { ...this.logObject.startVideoPageClosed };
              data.currentProcess = "Customer closed the Video KYC page";
              data.callId = this.requestId ? this.requestId : "";
              data.callDuration = {};
              data.callDuration.endTime = Date.now();
              logReportData(this.socket, initiationId, data);
            }
            catch (err) {
              console.log(err);
            }
          } else {
            try {
              let initiationId = sessionStorage.getItem('initiationId');
              let data = {};
              data.currentProcess = "Setting isCallActive flag to false by Customer since RE disconnected";
              data.isCallActive = false;
              logReportData(this.socket, initiationId, data);
            }
            catch (err) {
              console.log(err);
            }
          }
          if (this.requestId) {
            let initiationId = sessionStorage.getItem('initiationId');
            let data = {};
            data.callDuration = {};
            data.callDuration.endTime = Date.now();
            data.callId = this.requestId ? this.requestId : "";
            logReportData(this.socket,initiationId,data);
            this.socket.emit("manualDisconnection" , {
                type: "callTimeStamps",
                abruptDrop: Date.now() + offset * 3600000,
            })
          }
          window.parent.postMessage(
            { message: "eventOnCallDropAfterConnect" },
            "*"
          );
          this.cleanUpListeners();
          if (!this.adminLeft) {
            sms.emit("presenterLeft", {});
            return null;
          }

        }
        return null;
      };


      this.workOnLayouts();

      let selfIns = this;
      this.speedThreshold = this.callData.configuration.minSpeedRequired || 200; // deafult 200
      this.isRecording = this.callData.configuration.isRecording || false;
      this.toogleCamera = this.callData.configuration.toogleCamera || false;
      this.useStunTurn = this.callData.configuration.useStunTurn || false;

      //get global configs
      let globalConfigs = await axiosInstance.get(end_points.get_global_configs(this.callData.customerId));
      await this.setGlobalConfigurations(globalConfigs?.data);

      if (this.callData.configuration.customDomain) {
        this.host =
          this.callData.configuration.customDomain +
          (this.callData.configuration.customPath || "");
      }

      this.imageLogo =
        this.callData.configuration.imageLogo || require("@/assets/signzy.png");

      // //setting cameraToggle
      this.allowCameraToggle =
        this.callData.configuration.allowUserToTurnOffCamera || false;
      //setting chat configuration
      if(this.callData && this.callData.configuration && this.callData.configuration.enableChat) {
        this.enableChat = true;
        this.colors.message.myself.bg = this.callData.configuration.endUserMessageBgColor || '#fb4141';
        this.colors.message.others.bg = this.callData.configuration.bankerMessageBgColor || '#fb4141';
        this.colors.message.myself.text = this.callData.configuration.endUserMessageTextColor || '#fff';
        this.colors.message.others.text = this.callData.configuration.bankerMessageTextColor || '#fff';
      }

      if (this.$store.getters.endUserData["sessionId"]) {
        try {
          this.sessionId = this.$store.getters.endUserData["sessionId"];
          this.email = this.$store.getters.endUserData["email"];

          //Wokring on SMS listeners
          sms = new SignzyMessagingService(this.sessionId);
          let sessionObj = await getSession(this.sessionId);

          this.instructions = sessionObj["users"][0]["instructions"];
          this.selfInstructions = sessionObj["users"][0]["instructions"];
          this.languages = sessionObj.languagesRequired || [];
          this.languages.push("ENGLISH");

          sms.emit("internetSpeed", selfIns.internetSpeed);
          let data = await getBrowserData();
          if (this.isFrontendDebugLogs) {
            sms.emit("Error Logger##" , {VKYClogger: {for: this.sessionId, browserData: data ,type: "browserData", timeStamp: Date.now()}});
          }

          //First MIS logging
          try{
            let initiationId = sessionStorage.getItem('initiationId');
            // Added redundant log; case: backop details were intermittently missing, at the time of dequeue!!!
            let data = this.$store.getters.dequeueDetails || {};
            if(!data){
              console.error("Dequeued details not found")
            }
            data.sessionId = this.sessionId;
            data.onPage = "startVideo"
            data.currentProcess = "session Id fetched in start video";
            data.callId = this.requestId ? this.requestId : "";
            data.callState = CallState.INITIATE;
            logReportData(this.socket,initiationId,data);
            this.$store.commit("setDequeueDetails", undefined);
          }
          catch(err){
            console.log(err);
          }
          sms.emit("reporting",{"initiationReportId":sessionStorage.getItem('initiationId')});

          this.ipData = {};
          if(!this.enableChime) {
            signzyStreamService = new SignzyVideoService({
              domain: this.virtualHost ||  this.$store.getters.envValues?.vhttpBind,
              vbind: this.virtualHost ||  this.$store.getters.envValues?.vhttpBind,
              conferenceName: this.sessionId.toLowerCase(),
              userInformation: {
                email: sessionObj["users"][0]["email"],
                displayName: sessionObj["users"][0]["name"],
              },
              useStunTurn: this.useStunTurn,
              htmlElement: "audioTracks",
              directRemote: "vid2",
              directLocal: "reflection",
              isAdmin: false,
              remoteVideoMuteCB: this.muteRemoteVideo,
              onRemoteTrackAdded: () => {}, 
              videoQuality: this.callData.configuration.videoQuality,
              jitsiLogLevel:this.callData.configuration.jitsiLogLevel,
              jitsiEvents: this.jitsiEvents,
              disableJitsiLogs: this.disableJitsiLogs,
              jitsiCodec: this.jitsiCodec
            });
          } else {
            signzyStreamService = new SignzyChimeVideoService(
                this.sessionId.toLowerCase(),
                chime_end_points.initiate_meeting(this.sessionId.toLowerCase().substr(1,64)),
                // `http://localhost:4000/api/meetings/${this.sessionId.toLowerCase().substr(1,64)}`,
                sessionObj["users"][0]["name"],
                sessionObj["users"][0]["email"],
                document.getElementById('reflection'),
                document.getElementById('vid2'),
                undefined,
                document.getElementById('audioTracks')
            );

              const currentAttendee = await signzyStreamService.initialise();

              const videoDevices = await signzyStreamService.getVideoInputDevices();
              const speakers = await signzyStreamService.getAvailableSpeakers();
              const microphones = await signzyStreamService.getAvailableMicrophones();

              if (videoDevices && videoDevices.length) {
                console.log("video devices:", videoDevices); 
                this.videoDevices = [];
                
                /**
                 * Fix: https://signzy.atlassian.net/browse/VKYC-6347
                 * where camera,mic,speaker labels for headless browser instance and mobile app was coming as "" 
                 */
                for (let i = 0; i < videoDevices.length; i++) {
                  this.videoDevices.push({
                    deviceId: videoDevices[i].deviceId,
                    groupId: videoDevices[i].groupId,
                    kind: videoDevices[i].kind,
                    label: videoDevices[i].label || `Camera ${i + 1}`
                  })
                }

                const firstVideoDeviceId = this.videoDevices[0].deviceId;
                this.selectedVideoDevice = this.videoDevices[0];
                await signzyStreamService.setVideoInputDevice(firstVideoDeviceId);
              }

              if (microphones && microphones.length) {
                console.log("microphone devices:", microphones);
                this.microphones = [];

                for (let i = 0; i < microphones.length; i++) {
                  this.microphones.push({
                    deviceId: microphones[i].deviceId,
                    groupId: microphones[i].groupId,
                    kind: microphones[i].kind,
                    label: microphones[i].label || `Mic ${i + 1}`
                  })
                }

                const firstMicrophone = this.microphones[0].deviceId;
                this.selectedMicrophone = this.microphones[0];
                await signzyStreamService.setMicrophone(firstMicrophone);
              }

              if (speakers && speakers.length) {
                console.log("speaker devices:", speakers);
                this.speakers = [];

                for (let i = 0; i < speakers.length; i++) {
                  this.speakers.push({
                    deviceId: speakers[i].deviceId,
                    groupId: speakers[i].groupId,
                    kind: speakers[i].kind,
                    label: speakers[i].label || `Speaker ${i + 1}`
                  })
                }
                const firstSpeaker = this.speakers[0].deviceId;
                this.selectedSpeaker = this.speakers[0];
                await signzyStreamService.setSpeaker(firstSpeaker);
              } else {
                await signzyStreamService.setSpeaker();
              }
              const observers = signzyStreamService.createMeetingObservers(currentAttendee);
              signzyStreamService.start(observers);
          }

          this.isThankYouRequired = this.callData.configuration.isThankYouRequired;
          // checking inititial visibility
          this.visibilityState(false);
          sms.on("adminLeft", async (data) => {
            this.adminLeft = true;
            sessionStorage.setItem('adminLeft', true)


            // Below line is added to prevent Error dialogue overlay.
            // Scenario: if the RE agent refreshes the page and 'vpnCheckFailed' was true
            // this would lead to the display of two popup boxes at the End Users screen 
            this.vpnCheckFailed = false;
            try {
              if(this.enableChime) {
                signzyStreamService.leaveRoom();
              } else {
                await signzyStreamService.leaveRoom();

              }
            } catch (error) {
              console.log("Error at hangup!", error);
            }
          });
          sms.on('REPong', (data) => {
            if(data.timeStamp <= (Date.now() + 3000)){
              this.endUserPingResponse = true;
              this.endUserPingSent = false;
              this.noResponseFromRe = false;
              this.showDropPromptHeartbeat = false;
            } else {
              this.endUserPingSent = false;
              this.heartBeatFailureHandler();
            }
            if(this.endUserPingResponseTimer){
              clearTimeout(this.endUserPingResponseTimer);
            }

          })
          sms.on("agentCameraChange", (cameraState) => {
            this.removeVideoMuted = cameraState;
          })
          sms.on("startVideoConference", (data) => {
            if (!this.callStarted) {
              this.callStarted = true;
              if (data) {
                this.showClock = data.showClock;
              }

              setInterval(() => {
                this.timeElapsed += 1;
                this.timeInCall = convertToRinger(this.timeElapsed);
              }, 1000);
            }
          });

          //rejoin the same call - currently "data" is not used
          sms.on("rejoin", (data) => {
            signzyStreamService.retryConnecting(this.isMute, this.isVideoDisabled);
          })

          sms.on("RELeftRoom", ()=>{
            signzyStreamService.leaveRoom().then(()=>{
              sms.emit("endUserLeftRoom");
            }).catch(err =>{
              console.error("Error while leaving room!", err);
              this.addLogToReport("Error while leaving room for p2p rejoin!");
            })
          });

          sms.on("RERejoined", ()=>{
            signzyStreamService.reconnectRoom().then(()=>{
              sms.emit("rejoined");
            }).catch(err =>{
              console.error("Error while reconnecting to the room from RE's end! ", err);
              this.addLogToReport("Error while reconnecting to the room for p2p rejoin!");
            })
          });

          sms.on("bankerMessage", (data) => {
            try {
              data.viewed = true;
              data.uploaded = true;
              sms.emit("bankerMsgAck", data.index);
              this.chatMessages.push(data);
              if(!this.showChatBox) {
                this.showNotificationIcon = true;
              }
              else{
                this.messageSeenAck();
              }
            } catch(err) {
              console.error("Error msg", err);
            }
          })

          sms.on("endUserMsgAck", (index) => {
            try {
              this.chatMessages[index].uploaded = true;
              this.notViewedMsgs.push(index);
            } catch(err) {
              console.error("Error ack", err);
            }
          })
          sms.on("endUserMessageSeenAck", () => {
              try {
                for(let i = 0 ; i < this.notViewedMsgs.length ; i++) {
                  this.chatMessages[this.notViewedMsgs[i]].viewed = true;
                }
                this.notViewedMsgs = [];
              } catch(err) {
                console.error("Error ack", err);
              }
          });
          //snackBar Messages from the banker end
          sms.on("snackBarMessage", (data) => {
            eventBus.$emit('vueSnack', data);
          })
          // this.emitHeartBeat();
          sms.on("startRecording", async (data) => {
           await this.forensicsLocationHandler();
          });
          sms.on("agent audio muted", (data) => {
            selfIns.remoteAudioMuted = data;
          });
          // Replying the RE's ping
          sms.on("ping", (data) => {
            sms.emit("pong" , {
              pong: Date.now()
            })
          })
          sms.on("heartbeatWorking" , (data) => {

            this.heartbeatFailed = false;
          });

          sms.on("heartbeatFailed", (data) => {
            if(!this.enableChime){
              this.videoErrorMessage = "Something went wrong. Please wait while we try to reconnect you to the agent.";
              this.heartbeatFailed = true;
            }
          })

          sms.on("heartbeatFailedDropOff", async (data) => {
            try {
              if(this.enableChime) {
                signzyStreamService.leaveRoom();
              } else {
                await signzyStreamService.leaveRoom();
              }
            } catch (error) {
              console.log("Error at hangup!", error);
            }
            this.socket.emit('callLeft');
            this.cleanUpListeners();
            this.$router.replace({
              path: "/unexpectedDrop/heartbeatFailed",
            });
          })
          sms.on("networkSpeedFine" , (data) => {
            this.speedThresholdFailed = false;
          })
          sms.on("networkSpeedTooSlow", (data) => {
            this.videoErrorMessage = "Your network is too slow. Please wait while we try to reconnect you.";
            this.speedThresholdFailed = true;
          });
          sms.on("networkSpeedSlowDropOff" , async (data) => {
            try {
              let initiationId = sessionStorage.getItem('initiationId');
              let formData = {};
              formData.callDuration = {};
              formData.callDuration.endTime = Date.now();
              formData.currentProcess = "network speed of user was slow";
              formData.callId = this.requestId ? this.requestId : "";

              logReportData(this.socket,initiationId,formData);
            } catch (err) {
              console.log(err);
            }
            try {
              if(this.enableChime) {
                signzyStreamService.leaveRoom();
              } else {
                await signzyStreamService.leaveRoom();
              }
            } catch (error) {
              console.log("Error at hangup!", error);
            }
            this.socket.emit('callLeft');
            this.cleanUpListeners();
            try {
              let initiationId = sessionStorage.getItem('initiationId');
              let data = {};
              data.status = "Unsuccessful (Technical failure)";
              data.subStatus = "Customer network is too slow";
              data.callId = this.requestId ? this.requestId : "";
              logReportData(this.socket,initiationId,data);
            } catch(err) {
                console.log(err);
            }
            this.$router.replace({
              path: "/unexpectedDrop/networkTooSlow",
            });
          })
          sms.on("showTimerButton",(data)=>{
          this.isVideoConference = this.$route.path.includes("/initiate/");
          this.showClock = !this.isVideoConference;
          })
          sms.on("changeInstruction", (data) => {
            console.log("asASAS", data.instructionNumber);
            selfIns.step = data.instructionNumber;
            selfIns.instructionDialog = true;
            selfIns.instructionsText = selfIns.instructions[selfIns.step].text;
            if (
              selfIns.instructions[data.instructionNumber].isIDCardBoxRequired
            ) {
              selfIns.showIDOverlay();
            } else {
              selfIns.hideIDOverlay();
            }

            if (!this.callStarted) {
              this.callStarted = true;
              this.ringerInterval = setInterval(() => {
                this.timeElapsed += 1;
                this.timeInCall = convertToRinger(this.timeElapsed);
              }, 1000);
            }
          });

          sms.on("giveMeSpeed", (data) => {
            sms.emit("internetSpeed", selfIns.internetSpeed);
          });

          sms.on("giveMeLanguage", (data) => {
            sms.emit("language", {
              language: this.language,
            });
          });

          //do here the jatra
          sms.on("giveMeLocation", async (data) => {
            console.log("Sending location data", this.ipData);
            sms.emit("ipData", this.ipData.data);
            if(this.isFrontendDebugLogs){
              sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId, data: this.ipData, type: "locationFailure0"}});
            }

          });
          sms.on("retryLocation",async (data)=>{
              await this.forensicsLocationHandler();
          })

          sms.on("captureScreenshot", (data) => {
            this.tempToken = data.tempAuth;
            this.clearScreenshotUpload("Taking a new screenshot");
            this.captureScreenshot();
          });

          sms.on("cancelScreenshotUpload", () => {
            this.clearScreenshotUpload("Screenshot Upload Cancelled by RE agent");
          });

          sms.on("grantSharePermission", async (data) => {
            if (this.email == data.email) {
              screenPub = await screenShare(this.sessionId);
            }
          });

          sms.on("hangUp", async (data) => {
            // window.removeEventListener('beforeunload' , this.promptMessage)
            if (!this.isLoadedInIframe) {
              sessionStorage.clear();
            }
            const currProcess = data.currentProcess ? data.currentProcess : false;
            if (data.success) {
              this.callCompleted = true;
              window.parent.postMessage(
                { message: "eventOnCallCompleted", agentFeedbackData: this.agentFeedbackData},
                "*"
              );

              try {
                let initiationId = sessionStorage.getItem('initiationId');
                let data = { ...this.logObject.processingData };
                data.currentProcess = currProcess ? currProcess : "call completed need to check callback";
                data.callId = this.requestId ? this.requestId : "";
                data.callDuration = {};
                data.callDuration.endTime = Date.now();
                logReportData(this.socket,initiationId,data);
              } catch(err){
                console.log(err);
              }
              this.hangUp();
            } else {
              this.forcedHangup = true;
              try {
                if(this.enableChime) {
                  signzyStreamService.leaveRoom();
                } else {
                  await signzyStreamService.leaveRoom();
                }
              } catch (error) {
                console.log("Error at hangup!!", error);
              }
              if (this.requestId) {
                axiosInstance.post(
                  end_points.update_call_timestamp(this.requestId),
                  {
                    abruptDrop: Date.now() + offset * 3600000,
                  }
                );
                try {
                  let initiationId = sessionStorage.getItem('initiationId');
                  let data = {};
                  data.callDuration = {};
                  data.callDuration.endTime = Date.now();
                  data.currentProcess = "Abrupt drop hangup event received";
                  data.callId = this.requestId ? this.requestId : "";
                  logReportData(this.socket,initiationId,data);
                } catch(err) {
                  console.log(err);
                }
                this.socket.emit("manualDisconnection", {cancelCleanUp: true});
              }
              window.parent.postMessage(
                { message: "eventOnCallDropAfterConnect" },
                "*"
              );
              if (!this.streamNotVisible && !this.heartbeatFailed && !this.speedThresholdFailed) {
                this.cleanUpListeners();
                try {
                  let initiationId = sessionStorage.getItem('initiationId');
                  let data = {};
                  data.status = "Customer Drop off";
                  data.subStatus = "abrupt";
                  data.callId = this.requestId ? this.requestId : "";
                  logReportData(this.socket,initiationId,data);
                } catch(err) {
                    console.log(err);
                }
                this.$router.replace({ //redirecting user to drophandler page as the design for abrupt is changed
                  path: "/unexpectedDrop/abrupt",
                });
              }
            }
          });

          sms.on("streamNotVisible", (data) => {
            this.streamNotVisible = true;
            this.videoErrorMessage = "Something went wrong in accessing your camera feed / microphone. Please wait while we try to access them.";
          })

          sms.on("streamNotVisibleDropOff", async (data) => {
            try {
                let initiationId = sessionStorage.getItem('initiationId');
                let formData = {};
                formData.callDuration = {};
                formData.callDuration.endTime = Date.now();
                formData.currentProcess = "stream was not visible"
                formData.callId = this.requestId ? this.requestId : "";
                logReportData(this.socket,initiationId,formData);
            } catch(err) {
              console.log(err);
            }
            try {
              if(this.enableChime) {
                signzyStreamService.leaveRoom();
              } else {
                await signzyStreamService.leaveRoom();
              }
            } catch (err) {
              console.log("Error at leaving room :::::", err);
            }
            this.socket.emit('callLeft');
            this.cleanUpListeners();
            try {
              let initiationId = sessionStorage.getItem('initiationId');
              let data = { ...this.logObject.noStream };
              data.callId = this.requestId ? this.requestId : "";
              logReportData(this.socket,initiationId,data);
            } catch(err) {
                console.log(err);
            }
            this.$router.replace({
              path: "/unexpectedDrop/noStream",
            });
          })

          sms.on("streamIsVisible", (data) => {
            this.streamNotVisible = false;
          })

          sms.on("changeLanguage", (data) => {
            this.setLanguage(data.language);
          });

          sms.on("getLocation", async (data) => {
            try {
              let browserdata = await getBrowserData();
              sms.emit("setLocation", {
                geo: this.ipData.data,
                browser: browserdata,
              });
            } catch (error) {
              console.log(error);
              eventBus.$emit("vueSnack", "Something went wrong!");
            }
          });

          sms.on("adminAlive", (data) => {
            this.adminAlive = true;
            sms.emit("presenterAlive", {});
            if(!this.heartBeatInitiated){
              this.sendHeartBeat();
            }
          });

          sms.emit("presenterAlive", {});

          sms.on("getConsentTimestamp", (data)=>{
            let consentData = sessionStorage.getItem("consentTimestamp") ? sessionStorage.getItem("consentTimestamp"): false;
            sms.emit("sendConsentTimestamp", consentData);
          });

          sms.on("agentFeedbackCaptured", (data)=> {
            console.log("Agent feedback received ", data);
            this.agentFeedbackData = data;
            sms.emit("agentFeedbackCapturedAck", {});
          });

          setTimeout(() => {
             this.disableMuteBtn=false;
          }, 2000)
          if(!this.heartBeatInitiated){
              this.sendHeartBeat();
          }

        } catch (error) {
          console.log(error);
          this.$router.replace({ //redirecting user to drophandler page where retry and reschedule options will be available
            path: "/unexpectedDrop/abrupt",
          });
        }
      } else {
        eventBus.$emit("vueSnack", "Please try to join link again, Failed to create session!");
      }
    }
  },
};
</script>

<style scoped>
:root {
  --available-height: 80vh; /* Fallback */
}
.journey-end-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--available-height)/1.2);
}
.upload-text {
  margin: 20px 10px;
}
.cancel-button {
  font-size: 25px;
  z-index: 100;
  color: red;
  pointer-events: auto;
  cursor: pointer;
}
.upload-progress {
  position: fixed;
  left: 40%;
  top: 40%;
  z-index: 1000;
  padding: 40px;
  background-color:lightgrey;
  border-radius: 10px;
}

@media screen and (max-width: 540px) {
  .upload-progress {
    left: 20%;
  }
}

.salutation {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 4vh;
  line-height: 1em;
  color: black !important;
}
.instructionText {
  font-size: 16px;
}
.feedback {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.videoGrad {
  z-index: 0.5;
  background: transparent
    linear-gradient(180deg, #00000000 0%, #00000004 26%, #000000 100%) 0% 0%
    no-repeat padding-box;
}

#idCardGradient {
  position: fixed;
}
#reflection {
  width: 100%;
  background: black;
}
.loader {
  color: #ffffff;
  font-size: 30px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#vid2 {
  width: 100%;
  height:100%;
  background: black;
  border: 2px white solid;
  position: relative;
}
.timerBtn{
  padding: 0px !important;
}
.customBtn {
  width: 200px;
}
.noBorder {
  border: 0px;
}
.hangup {
  transform: rotate(225deg);
}
.remoteUser {
  width: 200px;
  height: 114px;
  margin: 10px;
  position: absolute;
  z-index: 1;
}

.instructionWrapper {
  color: white;
  font-size: 16px;
  padding-top: 2vh;
}
.idCardGrad, .idCardGrad_chat {
  z-index: 0.4;
  background: url("~@/assets/rectangle.svg");
  background-position: center;
  background-size: cover;
  display: none;
  justify-content: center;
  align-items: center;
}

.questionary {
  position: absolute;
  bottom: 74px;
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.action-bar{
  position: relative;
}

.action-btns {
  display: flex;
  justify-content: center;
}

@media screen and (min-width:451px) and (max-width:750px){
  .questionary{
  position: absolute;
  bottom: 50px;
  font-size: 18px;
  }
}

@media screen and (max-width:450px) {
  .questionary{
  position: absolute;
  bottom: 35px;
  font-size: 16px;
  }
}



@media screen and (max-width: 540px) {

  .deviceDropdown{
    width: 100%;
  }
}

@media screen and (min-width: 541px) {
  .deviceDropdownContainer{
    display: flex;
    justify-content: space-between;
  }

  .deviceDropdown{
    width: 30%;
  }
}

@media screen and (min-width: 768px) {
  .main_body {
    display: flex;
    width: 100%;
    position: relative;
  }
  #languageContainer_chat {
    position: absolute;
    width: calc(100% - 350px) !important;
    z-index: 1;
  }
  #languageContainer {
    position: absolute;
    width: 100%  !important;
    z-index: 1;
  }
  .mainVideo_chat {
    width: calc(100% - 350px) !important;
  }
  .mainVideo {
    width: 100% !important;
  }
  #videoGradient_chat {
    position: absolute;
    width: calc(100% - 350px) !important;
    height: 20% !important;
  }
  #videoGradient {
    position: absolute;
    width: 100% !important;
    height: 20% !important;
  }
  .idCardGrad {
    width: 100% !important;
    height: 100% !important;
  }
  .idCardGrad_chat {
    width: calc(100% - 350px) !important;
    height: 100% !important;
  }
  .permissionProgress_chat {
    position: absolute;
    color: white;
    width: calc(100% - 350px) !important;
  }
   .permissionProgress {
    position: relative;
    color: white;
    width: 100% !important;
  }
  .chatBox_show {
    width: 350px;
    height: 70% !important;
    position: absolute;
    bottom: 75px;
    right: 0;
    z-index: 3;
  }
  .chatBox_hide {
    width: 0px;
    height: 80% !important;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .video-error-message {
    position: absolute;
    left: 2%;
    bottom: 100%;
    right: 2%;
    z-index: 2;
  }
  
}

@media screen and (max-width: 767px) {
  .main_body {
    display: flex;
    width: 100%;
    position: relative;
  }
  #languageContainer, #languageContainer_chat {
    position: absolute;
    left: 22px;
    width: 100% !important;
    z-index: 1;
  }
  #videoGradient, #videoGradient_chat {
    position: absolute;
    width: 100% !important;
    height: 20% !important;
  }
  .mainVideo, .mainVideo_chat {
    width: 100% !important;
  }
  .permissionProgress, .permissionProgress_chat {
    position: absolute;
    color: white;
    width: 100% !important;
  }
  .idCardGrad, .idCardGrad_chat {
    width: 100% !important;
    height: 100% !important;
  }
  .chatBox_show {
    width: 100%;
    height: 60% !important;
    position: absolute;
    bottom: 64px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 2;
  }
  .quick-chat-container {
    padding-bottom: 10px !important;
    background: #fff !important;
  }
  .video-error-message {
    position: absolute;
    bottom: 100%;
    left: 2%;
    right: 2%;
    z-index: 2;
  }

}
</style>
